<template>
  <div>
    <v-container class="pa-0 my-3" style="max-width: 1300px !important;">
      <v-row no-gutters>
        <v-col cols="12" md="9">

          <div
            style="color: #1872e1; font-size: 1.4em; letter-spacing: -1px !important; font-weight: 700 !important"
            :class="isMobile ? 'pt-10 pl-12' : 'pt-12 pl-12'"
            class="mb-1 font-rubik">
            <span>examenselectivitat</span>
          </div>

          <h1 class="font-rubik font-weight-bold blue-grey--text text--darken-3 pb-0 pb-1"
              :class="isMobile ? 'pl-12' : 'pl-12'"
              style="font-size: 36px"
          >
            Calculadora de Nota Selectivitat i Batxillerat
          </h1>


          <div class="pa-12 pt-0">

            <p>Calcula la teva nota d'accés a la universitat omplint els següents camps (les notes són sobre 10).
              D'aquesta forma podràs
              preveure la nota del teu expedient de batxillerat.</p>

            <h2 class="font-rubik font-weight-bold blue-grey--text text--darken-3 pb-2 mt-10"
                style="font-size: 21px"
            >
              Nota de Batxillerat
            </h2>
            <v-text-field
              outlined dense
              class="calculadora-field mb-3"
              color="primary"
              type="number"
              background-color="#fdfdfd"
              label="Nota de Batxillerat"
              :rules="notaRules"
              persistent-hint
              v-model.number="notaBatxillerat"
            >
            </v-text-field>
            <h2 class="font-rubik font-weight-bold blue-grey--text text--darken-3 pb-2 mt-5"
                style="font-size: 21px" id="temes"
            >
              Fase General
            </h2>
            <v-text-field
              outlined dense
              class="calculadora-field mb-3"
              color="primary"
              :rules="notaRules"
              type="number"
              background-color="#fdfdfd"
              label="Català"
              v-model.number="notaCatala"
            >
            </v-text-field>
            <v-text-field
              outlined dense
              class="calculadora-field mb-3"
              color="primary"
              type="number"
              :rules="notaRules"
              background-color="#fdfdfd"
              label="Castellà"
              v-model.number="notaCastella"
            >
            </v-text-field>
            <v-text-field
              outlined dense
              class="calculadora-field mb-3"
              color="primary"
              :rules="notaRules"
              type="number"
              background-color="#fdfdfd"
              label="Llengua estrangera"
              v-model.number="notaLlengua"
            >
            </v-text-field>
            <v-text-field
              outlined dense
              class="calculadora-field mb-3"
              color="primary"
              :rules="notaRules"
              type="number"
              background-color="#fdfdfd"
              label="Història"
              v-model.number="notaHistoria"
            >
            </v-text-field>
            <v-text-field
              outlined dense
              :rules="notaRules"
              class="calculadora-field mb-3"
              color="primary"
              type="number"
              background-color="#fdfdfd"
              label="Optativa"
              v-model.number="notaOptativa"
            >
            </v-text-field>
            <h2 class="font-rubik font-weight-bold blue-grey--text text--darken-3 pb-2 mt-5"
                style="font-size: 21px"
            >
              Fase específica
            </h2>
            <v-row no-gutters>
              <v-col cols="6" md="2" class="pr-1">
                <v-select v-model="Multiplicador1"
                          outlined dense
                          class="calculadora-field mb-3"
                          background-color="#fdfdfd"
                          color="primary"
                          :items="[0.1, 0.2]"
                ></v-select>
              </v-col>
              <v-col cols="6" md="10" class="pl-1">
                <v-text-field
                  outlined dense
                  class="calculadora-field mb-3"
                  :rules="notaRules"
                  color="primary"
                  type="number"
                  background-color="#fdfdfd"
                  label="Específica 1"
                  v-model.number="Especifica1"
                >
                </v-text-field>
              </v-col>

            </v-row>
            <v-row no-gutters>
              <v-col cols="6" md="2" class="pr-1">
                <v-select v-model="Multiplicador2"
                          outlined dense
                          class="calculadora-field mb-3"
                          background-color="#fdfdfd"
                          color="primary"
                          :items="[0.1, 0.2]"
                ></v-select>
              </v-col>
              <v-col cols="6" md="10" class="pl-1">
                <v-text-field
                  outlined dense
                  class="calculadora-field mb-3"
                  :rules="notaRules"
                  color="primary"
                  type="number"
                  background-color="#fdfdfd"
                  label="Específica 2"
                  v-model.number="Especifica2"
                >
                </v-text-field>
              </v-col>


            </v-row>


            <p v-if="errorText"
               class="tit font-weight-bold red--text text--darken-3 mt-5 mb-0 text-end"
               style="font-size: 21px"
            >
              *{{ errorText }}
            </p>

            <v-dialog v-model="solution"
                      max-width="700"
                      style="position: relative"
                      overlay-color="white"
                      close-delay="pa-0"
                      content-class="rounded-xl shadow-small"
            >
              <v-card class="text-center rounded-xl">
                <v-card-title class="pa-0 ma-0 pa-2 pb-4">
                  <v-spacer></v-spacer>
                  <v-btn icon color="grey lighten-1" @click="solution = false">
                    <v-icon>{{ mdiClose }}</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <p class="font-rubik ma-0 pb-12 pt-3"
                     v-if="notaTotal > 0 || true"
                     style="font-size: 36px; width: 100%;"
                  >
                    <span style="font-size: 30px" class="font-weight-bold">
                    Moltes felicitats!
                    </span>
                    <br>
                    <br>
                    La teva nota de la selectivitat és un
                    <br>
                    <span
                      style="font-size: 40px"
                    class="font-weight-bold indigo--text font-rubik">
                    {{ notaTotal.toFixed(3) }}</span>
                    <br>
                    sobre 14.000!
                  </p>

                  <p v-if="notaTotal > 0"
                     class="grey--text pb-12 ma-0 font-rubik"
                     style="font-size: 14px !important;"
                  >
                    (Nota total = Nota Batxillerat * 0.6 + Fase General * 0.4 + Esp1 * {{ Multiplicador1 }} + Esp2
                    * {{ Multiplicador2 }})
                  </p>
                </v-card-text>

              </v-card>
            </v-dialog>

            <v-row no-gutters class="justify-end">
              <v-hover class="transition-linear-out-slow-in d-flex"
                       v-slot:default="{ hover }">
                <v-btn :color="hover ?'primary' : '#256EFF'"
                       width="230"
                       height="58"
                       @click="calcNota"
                       :block="$vuetify.breakpoint.smAndDown"
                       dark
                       depressed
                       style="letter-spacing: 0.7px !important;"
                       class="text-none title mt-6"
                >
                  Calcular
                </v-btn>
              </v-hover>
            </v-row>


          </div>
        </v-col>
        <!-------------------------------------->
        <!-- Right Adsense                    -->
        <!-------------------------------------->
        <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="3">
          <div
            v-if="$root.currentUser === null || !$root.currentUser.premium"
            class="sticky">

            <adsense
              class="mt-6"
              slotter="8444206542"></adsense>

          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mdiClose} from '/src/assets/mdi.json'

import Adsense from "../../components/anuncis/Adsense";
// import confetti from "canvas-confetti";

export default {
  components: {Adsense},
  created() {
  },
  metaInfo() {

    return {
      title: `Calculadora de nota d'accés a la universitat`,
      meta: [
        {charset: 'utf-8'},
        {
          name: 'description',
          content: 'Calcula la nota de la selectivitat i de batxillerat. Entraràs a la carrera que vols? Entra i calcula la teva nota d\'accés a la universitat'
        },
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  data() {
    return {
      mdiClose: mdiClose,
      solution: false,
      notaBatxillerat: null,
      notaCatala: null,
      notaCastella: null,
      notaLlengua: null,
      notaHistoria: null,
      notaOptativa: null,
      Especifica1: null,
      Especifica2: null,
      Multiplicador1: 0.2,
      Multiplicador2: 0.2,
      notaTotal: 0,
      errorText: null,
      notaRules: [v => !!v || 'Aquest camp és obligatori', v => (v && v >= 0) || 'La nota no pot ser negativa', v => (v && v <= 10 || 'La nota no pot ser superior a 10')]
    }
  },
  computed: {
    isDisabled() {
      return this.notaBatxillerat == null ||
        this.notaCatala == null ||
        this.notaCastella == null ||
        this.notaLlengua == null ||
        this.notaHistoria == null ||
        this.notaOptativa == null ||
        this.Especifica1 == null ||
        this.Especifica2 == null
    },
    mitjaComunes() {
      return (this.notaCatala + this.notaCastella + this.notaLlengua + this.notaHistoria + this.notaOptativa) / 5
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'md'
    }
  },
  methods: {
    goToAuth() {
      this.$store.state.step = 1
      this.authStatus = 1
      this.$store.commit('toggleAuth')
    },
    limitNota(nota) {
      return nota >= 0 && nota <= 10
    },
    isAprovat(nota) {
      return nota >= 5 && nota <= 10
    },
    calcNota() {
      this.notaBatxillerat = parseFloat(this.notaBatxillerat)
      this.notaCatala = parseFloat(this.notaCatala)
      this.notaCastella = parseFloat(this.notaCastella)
      this.notaLlengua = parseFloat(this.notaLlengua)
      this.notaHistoria = parseFloat(this.notaHistoria)
      this.notaOptativa = parseFloat(this.notaOptativa)
      this.Especifica1 = parseFloat(this.Especifica1)
      this.Especifica2 = parseFloat(this.Especifica2)

      if (this.isAprovat(this.notaBatxillerat)) {
        if (this.limitNota(this.notaBatxillerat) &&
          this.limitNota(this.notaCatala) &&
          this.limitNota(this.notaCastella) &&
          this.limitNota(this.notaLlengua) &&
          this.limitNota(this.notaHistoria) &&
          this.limitNota(this.notaOptativa) &&
          this.limitNota(this.Especifica1) &&
          this.limitNota(this.Especifica2)) {
          if (parseFloat(this.notaBatxillerat) * 0.6 + parseFloat(this.mitjaComunes) * 0.4 < 5) {
            this.errorText = 'La nota de batxillerat amb la nota de la fase general ha de ser major o igual a 5.'
            this.notaTotal = null
          } else {
            if (this.isAprovat(this.Especifica1) && this.isAprovat(this.Especifica2)) {
              this.errorText = ''
              this.notaTotal = parseFloat(this.notaBatxillerat) * 0.6 + parseFloat(this.mitjaComunes) * 0.4 + this.Multiplicador1 * parseFloat(this.Especifica1) + this.Multiplicador2 * parseFloat(this.Especifica2)
            } else {
              if (this.isAprovat(this.Especifica1)) {
                this.errorText = ''
                this.notaTotal = parseFloat(this.notaBatxillerat) * 0.6 + parseFloat(this.mitjaComunes) * 0.4 + this.Multiplicador1 * parseFloat(this.Especifica1)
              } else if (this.isAprovat(this.Especifica2)) {
                this.errorText = ''
                this.notaTotal = parseFloat(this.notaBatxillerat) * 0.6 + parseFloat(this.mitjaComunes) * 0.4 + this.Multiplicador2 * parseFloat(this.Especifica2)
              } else {
                this.errorText = ''
                this.notaTotal = parseFloat(this.notaBatxillerat) * 0.6 + parseFloat(this.mitjaComunes) * 0.4
              }
            }
            this.solution = true;
            this.start();
          }
        } else {
          this.errorText = 'Totes les notes han de ser més grans o igual que 0 i més petites o igual que 10.'
          this.notaTotal = null
        }
      } else {
        this.notaTotal = null
        this.errorText = "Has d'aprovar el batxillerat amb més d'un 5."
      }
    },
    start() {
      this.fire(0.25, {
        spread: 26,
        startVelocity: 55
      })
      this.fire(0.2, {
        spread: 60
      })
      this.fire(0.35, {
        spread: 100,
        decay: 0.91
      })
      this.fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92
      })
      this.fire(0.1, {
        spread: 120,
        startVelocity: 45
      })
    },
    fire(particleRatio, opts) {
      const count = 200
      const defaults = {
        origin: {y: 0.7}
      }
      // confetti(Object.assign({}, defaults, opts, {
      //   particleCount: Math.floor(count * particleRatio)
      // }))
    },
  }
}
</script>

<style scoped>
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 70px;
}

.calculadora-field >>> .v-input__slot {
  margin-bottom: 0 !important;
}

.calculadora-field >>> .v-label {
  top: 14px !important;
}

.calculadora-field >>> .v-input__slot {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  height: 48px;
}
</style>
